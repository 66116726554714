import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom'
import s from './questionPage.module.css'
import QuestionCard from "./QuestionCard/QuestionCard";
import Loader from "../../Static/Loader/Loader";
import QuestionState from '../../State/QuestionState/QuestionState'
import {Link} from 'react-router-dom'
import QuestionCardDesctop from "./QuestionCardDesctop/QuestionCardDesctop";

 // isLoad={isLoad} progress={progress} loadQuizzes={loadQuizzes} quizzes={quizzes}
const QuestionPage = (props) => {

    const [tasks, setTasks] = useState([])
    const [quizId, setQuizId] = useState({})
    const [currentTaskNumber, setCurrentTaskNumber] = useState({})
    const [toMobile, setToMobile] = useState(true)

    const {id} = useParams();

    useEffect(()=>{
        if(!props.isLoad){
            props.loadQuizzes()
        }else{
            const currentQuiz = props.quizzes.find(q=>q.id===id)
            setQuizId(currentQuiz.id)
            const taskNumberFromProgress = props.progress.currentTaskNumber.find(p=>p.quiz===currentQuiz.id)
            setCurrentTaskNumber(taskNumberFromProgress?taskNumberFromProgress.taskNumber:0)
            setTasks(currentQuiz.tasks)
            // console.log(currentQuiz.tasks[taskNumberFromProgress?taskNumberFromProgress])
        }
        if ( navigator.userAgent.indexOf('Android')>0 ||navigator.userAgent.indexOf('Android')>0 ||navigator.userAgent.indexOf('BlackBerry')>0 ||navigator.userAgent.indexOf('iPhone')>0 ||navigator.userAgent.indexOf('iPad')>0 ||navigator.userAgent.indexOf('iPod')>0 ||navigator.userAgent.indexOf('Opera Mini')>0 ||navigator.userAgent.indexOf('IEMobile')>0 ){
            setToMobile(true)
        }else
            setToMobile(false)
    },[props.isLoad])

    const nextTask=()=>{
        setCurrentTaskNumber(currentTaskNumber+1)
    }

    const checkAnswer = async (latitude, longitude) =>{
        const result = await QuestionState.pullAnswer(quizId, {latitude,longitude})
        return result
    }

    if(props.isLoad)
    return(
        <div className={s.wrapper}>
            {tasks.length>0?
            <>
                { toMobile?
                    <QuestionCard currentTaskNumber={currentTaskNumber} task={tasks[currentTaskNumber]}
                                  nextTask={nextTask} checkAnswer={checkAnswer}
                                  setProgress={props.setProgress}
                                  isFinish={currentTaskNumber+1 === tasks.length}/>:
                    <QuestionCardDesctop currentTaskNumber={currentTaskNumber} task={tasks[currentTaskNumber]}
                                  nextTask={nextTask} checkAnswer={checkAnswer}
                                  setProgress={props.setProgress}
                                  isFinish={currentTaskNumber+1 === tasks.length}/>
                }
            </>:
            <div>В данном квесте пока нет вопросов</div>
            }
        </div>
    )
    else
        return <Loader/>
}

export default QuestionPage;