import React, {useEffect, useState} from 'react';
import s from './QuizPage.module.css'
import QuizState from "../../State/Quiz/QuizState";
import QuizCard from "./QuizCard/QuizCard";
import Loader from "../../Static/Loader/Loader";
import Cookie from 'js-cookie'
import {Link} from 'react-router-dom'
import foto from "../Static/resource/287110.jpg";

const QuizPage = (props) => {
    const [role, setRole] = useState('')
    const [contract, setContract] = useState(false)
    const [isViewBack, setIsViewBack] = useState(false)
    useEffect(()=>{
        if(!props.isLoad)
            props.loadQuizzes()
        setRole(Cookie.get('role'))
        const isContract = Cookie.get('contract')
        if(isContract) {
            setContract(true)
            setIsViewBack(true)
        }
    },[])

    return (
        <div>
            {role && role === 'token'?
                <Link to={'./token'}>
                    <div>Показать токен</div>
                </Link>:<></>
            }
            {props.isLoad?
                <div className={s.wrapper}>
                    {isViewBack? <img src={foto} className={s.logoBackFoto} alt=""/>:<></>}
                    <div className={s.back_color}></div>
                    {props.quizzes.length>0?<div>
                        {props.quizzes.map((quiz, index)=>{
                            return(
                                <>
                                    {contract?
                                        <QuizCard key={'quiz_card_'+index} quiz={quiz} isFinish={props.progress.isFinished.indexOf(quiz.id)>=0}
                                                  progress={props.progress.currentTaskNumber.find(p=>p.quiz === quiz.id)}/>:
                                        <>
                                            <div className={s.title}>Правила прохождения квеста</div>
                                            <div className={s.text}>
                                                <p>Для прохождения квеста c мобильного устройства необходимы данные геолокации.</p>
                                                <p>После нажатия кнопки "Начать" появится модальное окно, в котором необходимо нажать кнопку "Разрешить".</p>
                                                <p>В вопросах квеста загаданы достопримечательности Тулы, расположенные в шаговой доступности от центра города. Для ответа необходимо подойти к загаданному месту и нажать кнопку "Проверить".</p>
                                                <p>Пожалуйста, будьте внимательны, соблюдайте правила дорожного движения, не заходите на газоны.</p>
                                                <p>Нажимая на кнопку "Продожить", вы даете согласие на обработку своих персональных данных.</p>
                                            </div>
                                            <div onClick={()=>{
                                                Cookie.set('contract', 'true')
                                                setContract(true)
                                                setIsViewBack(true)
                                            }
                                            } className={'btn_blue '+ s.btn_continue}>Продолжить</div>
                                        </>

                                    }


                                </>

                                          )
                        })}
                    </div>:<div>Нет заданий</div>}
                </div>:
                <Loader/>}

        </div>
    );
};

export default QuizPage;