import React, {useState} from 'react';
import s from './Maps.module.css'

// index={props.index} changeTask={props.changeTask}
const Maps = (props) => {
    // changeTask={checkAnswer} pullAnswer={pullAnswer} close={closeModal} geolocation={geolocation}


    const loadScript = (src, onLoad) => {
        const script = document.createElement("script");

        script.src = src;
        script.async = true;
        document.body.appendChild(script);
        script.onload = onLoad;
    };

    const init = () => {

        let myPlacemark
        const  myMap = new window.ymaps.Map("map", {
            // Координаты центра карты.
            // Порядок по умолчанию: «широта, долгота».
            // Чтобы не определять координаты центра карты вручную,
            // воспользуйтесь инструментом Определение координат.
            center: props.geo.length>0?[props.geo[0],props.geo[1]]:[54.19517880061219, 37.61922717291121],
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 16
        }, {
            searchControlProvider: 'yandex#search'
        })

        if(props.geo.length>0){
            myMap.geoObjects.add(new window.ymaps.Placemark([props.geo[0], props.geo[1]]))
        }



        myMap.events.add('click', function (e) {
                    const  coords = e.get('coords');
                    props.changeTask(coords)
                        if (myPlacemark) {
                            myPlacemark.geometry.setCoordinates(coords);
                        }
                        // Если нет – создаем.
                        else {
                            myPlacemark = createPlacemark(coords);
                            myMap.geoObjects.add(myPlacemark);
                            // Слушаем событие окончания перетаскивания на метке.
                            myPlacemark.events.add('dragend', function () {
                                getAddress(myPlacemark.geometry.getCoordinates());
                            });
                        }
                        getAddress(coords);
                    });

                    // Создание метки.
                    function createPlacemark(coords) {
                        return new  window.ymaps.Placemark(coords, {
                            iconCaption: 'поиск...'
                        }, {
                            preset: 'islands#violetDotIconWithCaption',
                            draggable: true
                        });
                    }
        function getAddress(coords) {
            myPlacemark.properties.set('iconCaption', 'поиск...');
            window.ymaps.geocode(coords).then(function (res) {
                var firstGeoObject = res.geoObjects.get(0);

                myPlacemark.properties
                    .set({
                        // Формируем строку с данными об объекте.
                        iconCaption: [
                            // Название населенного пункта или вышестоящее административно-территориальное образование.
                            firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                            // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
                            firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                        ].filter(Boolean).join(', '),
                        // В качестве контента балуна задаем строку с адресом объекта.
                        balloonContent: firstGeoObject.getAddressLine()
                    });
            });
        }

    };
    React.useEffect(() => {

        loadScript("https://api-maps.yandex.ru/2.1/?apikey=061756e0-61d1-4e4e-8bc0-1a0725db72f4&lang=ru_RU", () => {

            window.ymaps.ready(init);
        });
    }, []);

    return (
        <div>
            <div id="map" className={s.maps}/>
        </div>
    );
};

export default Maps;