import React, {useState} from 'react';
import s from './ForgetModal.module.css'
import userState from '../../../State/UserState/UserState'
import Cookie from 'js-cookie'
import Cookies from "js-cookie";

const ForgetModal = (props) => {
    const [email, setEmail] = useState('')
    const [emailIsConfirmed, setEmailIsConfirmed] = useState(false)
    const [emailIsFailed, setEmailIsFailed] = useState(false)
    const [key, setKey] = useState('')
    const [newPassword, setNewPassword] = useState('')

    const checkMail = (email)=>{
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return reg.test(email)
    }

    return (

        <>
            <div  onClick={()=>{
                props.close()
            }} className={s.modal_background}></div>
            <div className={s.wrapper}>
                <div className={s.wrapper_input}>
                    <input onChange={(e)=> {
                        setEmailIsFailed(false)
                        setEmail(e.target.value.trim())

                    }} className={s.input_box} type="text" placeholder={'введите e-mail'} value={email}/>
                    <div className={'btn_blue'} onClick={async()=>{
                            const result = await userState.checkEmail(email)
                            console.log(result)
                            if(result.warning){
                                props.warning('Ошибка сервера. Попробуйте восстановить пароль позже')
                            }else if(result.isCheck){
                                setEmailIsFailed(false)
                                setEmailIsConfirmed(true)
                            }else{
                                setEmailIsFailed(true)
                            }
                    }}>Отправить</div>
                    {emailIsFailed?<div className={s.error_text}>{'Нет пользователя с указанным e-mail'}</div>:<></>}

                    {emailIsConfirmed?<>
                        <div className={s.not_error_text}>{'На указанный e-mail выслан код подтверждения.'}</div>
                        <input className={s.input_box} type="text"
                               placeholder={'введите код подтверждения'}
                               value={key}
                               onChange={e=>setKey(e.target.value)}
                        />
                        <input className={s.input_box} type="text"
                               placeholder={'введите новый пароль'}
                               value={newPassword}
                               onChange={e=>setNewPassword(e.target.value)}
                        />
                        <div className={'btn_blue'} onClick={async()=>{
                            const result = await userState.updateUserPassword(email, key, newPassword)
                            console.log(result)
                            if(result.warning && !result.token){
                                props.warning('Ошибка сервера. Попробуйте восстановить пароль позже')
                            }else {
                                Cookies.set('token', result.token, { expires: 20 })
                                Cookies.set('role', result.role, { expires: 20 })
                                window.location.href = window.location.origin
                            }
                        }}>Изменить</div>
                    </>:<></>}

                </div>
                <div onClick={()=>{
                    props.close()
                }} className={s.btn_close}>Закрыть</div>
            </div>
        </>
    );
};

export default ForgetModal;