import React, {useState} from 'react';
import authState from '../../State/AuthState/AuthState'
import Cookies from 'js-cookie'
import {Link} from 'react-router-dom'
import s from './authPage.module.css'
import ForgetModal from "./ForgetModal/ForgetModal";

const AuthPage = (props) => {
    const [login,setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [isViewForget, setIsViewForget] = useState(false)
    const loginActive = async ()=>{
        const result = await authState.login(login, password)
        if (result.warning){
            setIsError(true)
            setErrorMessage(result.message)
        }else{
            Cookies.set('token', result.token, { expires: 20 })
            Cookies.set('role', result.role, { expires: 20 })
            window.location.href = window.location.origin
        }

    }
    const closeModal = ()=>{
        setIsViewForget(false)
    }

    const handleChangeLogin = event => {
        setLogin(event.target.value)
    }

    const handleChangePassword = event => {
        setPassword(event.target.value)
    }

    return (
        <>

                <div className={s.wrapper}>
                <div className={s.form}>
                    <div className={s.title}>Авторизация</div>
                    <div className={s.text_box_wrapper}>
                            <input
                                onChange={handleChangeLogin}
                                id="login"
                                type="text"
                                placeholder='Имя пользователя или e-mail'
                                className={s.text_box}/>
                            
                    </div>
                    <div className={s.text_box_wrapper}>
                            <input
                                onChange={handleChangePassword}
                                id="password"
                                type={"password"}
                                placeholder='Пароль'
                                className={s.text_box}/>
                    </div>
                    {isError?
                        <div className={s.error_row}>
                            <label>{errorMessage?errorMessage:'Ошибка при вводе логина или пароля'}</label>
                        </div>:
                        <div  className={s.error_row_emty}></div>
                    }
                    <div className={s.button_enter}  onClick={()=>{loginActive()}}>Войти</div>
                    <Link to={'./registration/site'}>
                        <div className={s.button_registration}>Регистрация</div>
                    </Link>
                    <div className={s.button_forget} onClick={()=>{setIsViewForget(true)}}>Забыл пароль</div>
                    {/* <div className='btn center modal-trigger ' href="#modal_auth">Авторизация по QR</div> */}
                </div>
                </div>
            {/* <ModalWindowAuthByQr isError={authState.isError} actionLoginQr={actionLoginQr} errorAuthQr={errorAuthQr}/> */}
            {isViewForget?<ForgetModal close={closeModal} warning={props.warning}/>:<></>}
            </>
    );
}

export default AuthPage;