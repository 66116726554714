import React, {useEffect, useState} from 'react';
import s from './MapPage.module.css'
import useGeolocation from 'react-hook-geolocation'


// index={props.index} changeTask={props.changeTask}
const MapPage = (props) => {
    const event = new Event('start');
    const [myMapX, setMyMap] = useState(null)
    const [playMark, setPlayMark] = useState(null)
    const geolocation = useGeolocation({
        enableHighAccuracy : true ,
        maxAge :          15000 ,
        timeout :             12000
    })
    if(myMapX && geolocation.latitude && geolocation.longitude){

        document.dispatchEvent(event);
        if(!playMark){
            console.log('playMark',playMark)
            const mark = new window.ymaps.Placemark([geolocation.latitude, geolocation.longitude] ,{},{
                preset: 'islands#blueCircleDotIconWithCaption',
                iconCaptionMaxWidth: '50'
            })
            myMapX.geoObjects.add(mark)
            setPlayMark(mark)
        }else{
            playMark.geometry.setCoordinates([geolocation.latitude, geolocation.longitude]);
        }
        myMapX.setCenter([geolocation.latitude, geolocation.longitude]);
    }
    // console.log(props.changeTask)
    const loadScript = (src, onLoad) => {
        const script = document.createElement("script");

        script.src = src;
        script.async = true;
        document.body.appendChild(script);
        script.onload = onLoad;
    };



    const init = () => {

        let myPlacemark

        const  myMap = new window.ymaps.Map("map", {
            // Координаты центра карты.
            // Порядок по умолчанию: «широта, долгота».
            // Чтобы не определять координаты центра карты вручную,
            // воспользуйтесь инструментом Определение координат.
            center: geolocation.latitude && geolocation.longitude ?[geolocation.latitude, geolocation.longitude]:[54.19517880061219, 37.61922717291121],
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: 16,
            controls: []
        }, {
            searchControlProvider: 'yandex#search'
        })

        setMyMap(myMap)

        // document.addEventListener('start', function (e) {
        //     console.log('start', props.geo[0],props.geo[1], props)
        //     if (myMapX)
        //         myMapX.setCenter([props.geo[0],props.geo[1]]);
        //     else
        //         myMap.setCenter([props.geo[0],props.geo[1]]);
        //     if (playMark) {
        //         playMark.geometry.setCoordinates([props.geo[0],props.geo[1]]);
        //     }
        //     // Если нет – создаем.
        //     else {
        //         const myPlacemark = createPlacemark([props.geo[0],props.geo[1]]);
        //         myMapX.geoObjects.add(myPlacemark);
        //         // Слушаем событие окончания перетаскивания на метке.
        //         setPlayMark(myPlacemark)
        //     }
        //
        // });

        // Создание метки.
        function createPlacemark(coords) {
            return new  window.ymaps.Placemark(coords, {
                iconCaption: 'поиск...'
            }, {
                preset: 'islands#violetDotIconWithCaption',
                draggable: true
            });
        }
        function getAddress(coords) {
            myPlacemark.properties.set('iconCaption', 'поиск...');
            window.ymaps.geocode(coords).then(function (res) {
                var firstGeoObject = res.geoObjects.get(0);

                myPlacemark.properties
                    .set({
                        // Формируем строку с данными об объекте.
                        iconCaption: [
                            // Название населенного пункта или вышестоящее административно-территориальное образование.
                            firstGeoObject.getLocalities().length ? firstGeoObject.getLocalities() : firstGeoObject.getAdministrativeAreas(),
                            // Получаем путь до топонима, если метод вернул null, запрашиваем наименование здания.
                            firstGeoObject.getThoroughfare() || firstGeoObject.getPremise()
                        ].filter(Boolean).join(', '),
                        // В качестве контента балуна задаем строку с адресом объекта.
                        balloonContent: firstGeoObject.getAddressLine()
                    });
            });
        }

    };
    React.useEffect(() => {

            loadScript("https://api-maps.yandex.ru/2.1/?apikey=061756e0-61d1-4e4e-8bc0-1a0725db72f4&lang=ru_RU", () => {
                    window.ymaps.ready(init);
            });


    }, []);

    return (
        <div className={s.wrapper}>
            <div id="map" style={{ width: '90%', height: '200px', margin:'0 auto 10px auto' }} />
        </div>
    );
};

export default MapPage;