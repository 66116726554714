import React, {useState} from 'react';
import {geolocated} from "react-geolocated";
import s from './questionCard.module.css'
import {Link} from 'react-router-dom'
import HistoryPage from "../HistoryPage/HistoryPage";
import ResultModal from "../ResultModal/ResultModal";
import MapPage from "../../MapPage/MapPage";
import useGeolocation from 'react-hook-geolocation'

const QuestionAdminCard = (props) => {
    const [isHistoryView, setIsHistoryView] = useState(false)
    const [isViewAnswer, setIsViewAnswer] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [isTrue, setIsTrue] = useState(false)
    const [geolocation, setGeolocation]=useState({})
    const  checkRoundCoordinate = (valueAnswer, valueOrigin)=>{
        const valueAnswerRound = Math.round(valueAnswer*100000)
        const valueOriginMin = Math.round(valueOrigin*100000) - 30
        const valueOriginMax = Math.round(valueOrigin*100000 )+ 30
        // console.log(valueOriginMin, valueOriginMax)
        // console.log(valueAnswerRound)
        return valueOriginMin <= valueAnswerRound && valueAnswerRound <= valueOriginMax
    }
    const  onGeolocationUpdate  =  g   =>  {
        setGeolocation(g)
        if(checkRoundCoordinate(props.task.geolocation[0],g.latitude) && checkRoundCoordinate(props.task.geolocation[1], geolocation.longitude))
            setIsTrue(true)
        else
            setIsTrue(false)
        }

    const geo = useGeolocation({
        enableHighAccuracy : true ,
        maxAge :          15000 ,
        timeout :             700
    },onGeolocationUpdate )

    const closeModal = ()=>{
        setIsViewAnswer(false)
    }
    return !geolocation || geolocation.error ? (
        <div>Геолокация не включена</div>
    ) :(
<>
            {!isHistoryView?
                <>
                <div className={s.card_wrapper}>
                    <div className={s.content}>
                        <div className={s.title}>Вопрос №{props.currentTaskNumber+1}</div>
                        <div className={s.text}>{props.task.text}</div>
                        {isTrue?<div style={{backgroundColor:'green'}}><div>{geolocation.latitude}, {geolocation.longitude}</div><div>{props.task.geolocation.toString()}</div></div>:
                            <div style={{backgroundColor:'red'}}><div>{geolocation.latitude}, {geolocation.longitude}</div><div>{props.task.geolocation.toString()}</div></div>
                        }
                    </div>
                    <MapPage/>
                    <div className={s.btn_wrapper}>
                        <div onClick={()=>{
                          props.nextTask(1)
                        }} className={'btn_blue'}>Next</div>

                        <div className={s.btn_wrapper}>
                            <div onClick={()=>{
                                props.nextTask(-1)
                            }} className={'btn_blue'}>Back</div>
                        {/*{props.task.img? <img src={props.task.img} alt=""/>:<></>}*/}

                    </div>
                </div>
                </div>
                </>:
                <>
                    <HistoryPage setIsHistoryView={setIsHistoryView} isFinish={props.isFinish} nextTask={props.nextTask} title={props.task.answer} text={props.task.description} img={props.task.img}/>
                </>}

    {isViewAnswer?<ResultModal close={closeModal} answer={answer}/>:<></>}
</>


    )
};

export default QuestionAdminCard;