import React, {useEffect} from 'react';
import Loader from "../../Static/Loader/Loader";
import {useParams} from "react-router-dom";
import userState from '../../State/AuthState/AuthState'
import Cookies from "js-cookie";

const LoginPage = () => {
    const {username, password} = useParams();
    const init = async ()=>{
        const result = await userState.login(username,password)
        if (result.warning){
            window.location.href = window.location.origin
        }else{
            Cookies.set('token', result.token, { expires: 20 })
            Cookies.set('role', result.role, { expires: 20 })
            window.location.href = window.location.origin
        }
    }

    useEffect(()=>{
        init()
    },[])
    return (
        <div>
            <Loader/>
        </div>
    );
};

export default LoginPage;