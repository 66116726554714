import React from 'react';
import s from './ResultModal.module.css'
import check from '../../Static/resource/check.svg'
import cross from '../../Static/resource/error-svgrepo-com.svg'

const ResultModal = (props) => {
    return (
        <>
            <div className={s.modal_background}/>
            <div className={s.wrapper}>
                {props.answer?<div className={s.message}>Верно!</div>:<div className={s.message}>Ответ неверен</div>}
                {props.answer?<img className={s.true_icon} src={check} alt=""/>:<img  className={s.error_icon} src={cross} alt=""/>}

            </div>

        </>
    );
};

export default ResultModal;