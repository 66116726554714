import React, {useEffect, useState} from 'react';
import userState from "../../State/UserState/UserState";
import Loader from "../../Static/Loader/Loader";
import s from './PromoCodePage.module.css'
import phone_svg from '../Static/resource/phone-svgrepo-com.svg'
import mail_svg from '../Static/resource/mail-svgrepo-com.svg'

const PromoCodePage = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.game}>Спасибо за игру!</div>
                <div className={s.game}>Скидка на покупку игровых наборов</div>
                <div className={s.number}>10%</div>
                <div className={s.propmo}>по промокоду</div>
                <div className={s.code}>{'TULA_1206'}</div>
                <div className={s.description}>
                    <p>По вопросам приобретения:</p>
                </div>
                <div className={s.wrapper_phone_map_none}>
                    <a className={s.phone} href="tel:+78007753437"><img className={s.phone_svg}
                                                                      src={phone_svg} alt=""/><span>8 (800) 775-34-37</span></a>
                    <a className={s.mail} href="mailto:info@rodnyeigry.ru"><img className={s.mail_svg}
                                                                              src={mail_svg} alt=""/><span>info@rodnyeigry.ru</span></a>
                </div>
            </div>
        </div>
    )
};

export default PromoCodePage;