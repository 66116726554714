import responseService from "../../Services/responseService";
import config from "../../config";
import Cookies from 'js-cookie';

class AuthState {

    async login(login, password){
        const response = await responseService.responseApiServer(config.URL_LOGIN,{username:login.toLowerCase(), password})
        return response
    }
}
const authState = new AuthState()
export default  authState