
import responseService from "../../Services/responseService";
import config from "../../config";

class QuizState {

    async getQuizzes(){
        try {
            const result = await responseService.responseApiServer(config.URL_GET_QUIZ)
            return result
        }catch (e) {
            return {warning:true, message:'Сервер недоступен. Попробуйте зайти позже.'}
        }

    }
}



export default  new QuizState();

