import React from 'react';
import s from './warniningModal.module.css'
const WarningModal = (props) => {
    return (
        <>
            <div  onClick={()=>{
                props.close()
            }} className={s.modal_background}></div>
            <div className={s.wrapper}>
                <div className={s.title}>{props.title}</div>
                <div className={s.message}>{props.text}</div>
                <div onClick={()=>{
                    props.close()
                }} className={s.btn_close}>Закрыть</div>
            </div>
        </>
    );
};

export default WarningModal;