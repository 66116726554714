import {BrowserRouter,  Route, Routes} from 'react-router-dom'
import Cookies from 'js-cookie';

import {observer} from "mobx-react-lite";
import AuthPage from "./Pages/AuthPage/AuthPage";

import React, {useEffect, useState} from "react";
import logoBack from './images/logo_tula.svg'
import s from './App.module.css'

import fm from './font/montserrat/stylesheet.css'
import fg from './font/gilroy/stylesheet.css'
import globalStyle from './index.css'

import FooterComponent from './Pages/Static/FooterComponent';

import SupportState from './State/SupportState/SupportState'
import SupportWindow from './Pages/Static/SupportWindow/SupportWindow';

import AuthorModal from "./Pages/AuthorModal/AuthorModal";
import NavBarChoiceQuiz from "./Pages/Static/NavBarChoiceQuiz";
import QuizPage from "./Pages/QuizPage/QuizPage";
import WarningModal from "./Pages/Static/warningModal/WarningModal";
import RegistrationPage from "./Pages/RegistrationPage/RegistrationPage";
import QuizState from "./State/Quiz/QuizState";
import QuestionPage from "./Pages/QuestionPage/QuiestionPage";
import MapPage from "./Pages/MapPage/MapPage";
import TokenPage from "./Pages/TokenPage/TokenPage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import QuestionAdminPage from "./Pages/QuestionAdminPage/QuiestionAdminPage";
import PromoCodePage from "./Pages/PromoCodePage/PromoCodePage";

const App = (observer(() => {
    document.body.style.zoom= 1.0;
    const token = Cookies.get('token')
    const [currentSizeWindow, setCurrentSizeWindow] = useState(window.outerWidth)
    const [isViewAuthor, setIsViewAuthor] = useState(false)
    const [isWarning, setIsWarning] = useState(false)
    const [warningText, setWarningText] = useState('')
    const [quizzes, setQuizzes] = useState([])
    const [isLoad, setIsLoad] = useState(false)
    const [progress,setProgress] = useState({})
    const closeModalWarning = ()=>{
        setIsWarning(false)
    }

    const warning = (text)=>{
        setWarningText(text)
        setIsWarning(true)
    }

    const closeWindowSupport = ()=>{
        SupportState.checkViewWindowSupport(false)
    }
    const sendSupportMessage = async (username, mail, text)=>{
        const result = await SupportState.sendSupportMessage(username, mail, text)
        return result
    }

    const loadQuizzes = async ()=>{
        setIsLoad(false)
        const result = await QuizState.getQuizzes()
        if(!result.warning){
            setProgress(result.progress)
            setQuizzes(result.quizzes)
        }else{
            warning(result.message)
        }
        setIsLoad(true)
    }



    window.addEventListener(`resize`, event => {
        setCurrentSizeWindow(window.outerWidth)
    }, false);

    return (
        <>
        <div className={s.app}>

            {currentSizeWindow>800?<img src={logoBack} className={s.logoBack} alt=""/>:<></>}
            {SupportState.viewWindowSupport?<SupportWindow sendSupportMessage={sendSupportMessage} closeWindow={closeWindowSupport}/>:<></>}
            {isViewAuthor?<AuthorModal closeWindow={setIsViewAuthor}/>:<></>}
            {isWarning?<WarningModal close={closeModalWarning} text={warningText} title={'Ошибка!'}/>:<></>}
            <NavBarChoiceQuiz/>
            {/*<GameQuest />*/}
            <BrowserRouter>
                <Routes>
                    <Route path='/registration/:token' element={<RegistrationPage warning={warning}/>}/>
                    <Route path='/login/:username/:password' element={<LoginPage warning={warning}/>}/>
                    <Route path='/map' element={<MapPage warning={warning} />}/>
                    <Route path='/promo' element={<PromoCodePage />}/>
                    <Route path='/admin_game/quest/:id' element={<QuestionAdminPage isLoad={isLoad} setProgress={setProgress} progress={progress} loadQuizzes={loadQuizzes} quizzes={quizzes} />}/>
                    <Route path='/token' element={<TokenPage warning={warning} />}/>
                    <Route path='/quest/:id' element={<QuestionPage isLoad={isLoad} setProgress={setProgress} progress={progress} loadQuizzes={loadQuizzes} quizzes={quizzes} />}/>
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                {/*<p>Нет такой страницы. Перейдите на <Link to={'/'}>главную</Link></p>*/}
                                {token?<QuizPage isLoad={isLoad} progress={progress} loadQuizzes={loadQuizzes} quizzes={quizzes} warning={warning}/>:<AuthPage warning={warning} />}
                            </main>
                        }
                    />

                </Routes>
            </BrowserRouter>
        </div>
        <FooterComponent viewAuthor={setIsViewAuthor}/>
        </>
    );
}))

export default App;
