
const config = {
    //URL_API:'http://127.0.0.1:8008/api/',
    URL_API:'https://quizserver.vityazgroup.ru:8448/api/',
    //URL_API:'http://quizserver.vityazgroup.ru:8000/api/',

    URL_GET_QUIZ:'quiz/get',

    URL_LOGIN:'user/login',
    URL_CHECK_USERNAME:'user/check_username',
    URL_CHECK_EMAIL:'user/check_email',
    URL_GET_USER_ID:'user/get_user_id',

    URL_REGISTRATION_USER:'user/registration',
    URL_UPDATE_USER_PASSWORD:'user/update_user_password_by_key',

    URL_CHANGE_GEO_ANSWER:'question/change_geo',

    URL_SEND_SUPPORT:'support/add',
    URL_GET_SUPPORT:'support/get',
    URL_DELETE_SUPPORT:'support/delete',
    URL_UPDATE_SUPPORT:'support/update',


}

export default config

