import React from 'react';
import s from './authorModal.module.css'
const AuthorModal = (props) => {
    return (
        <>
            <div onClick={()=>{   props.closeWindow(false)}} className={'modal_background'}></div>


            <div className={s.wrapper}>
                <div className={s.close_window} onClick={()=>{
                    props.closeWindow(false)
                }}>✕</div>

                <div className={s.content}>
                    <span>Авторы викторины – команда Центра «Витязь». Более 15 лет мы работаем в сфере реквизитных игровых технологий. </span><br/>
                    <span>Мы аккумулировали собственный опыт и привлекли профильных специалистов для создания викторины, направленной на укрепление знаний молодого поколения об историческом и культурном наследии страны.</span><br/>
                    <span>Научным консультантом проекта является Юлия Анатольевна Селезнева, кандидат психологических наук, доцент ТГПУ имени Л.Н. Толстого, член-корреспондент академии имиджелогии, бизнес-тренер.</span>
                </div>
            </div>
        </>
    );
};

export default AuthorModal;