import React, {useState} from 'react';
import {geolocated} from "react-geolocated";
import s from './questionCard.module.css'
import {Link} from 'react-router-dom'
import HistoryPage from "../HistoryPage/HistoryPage";
import ResultModal from "../ResultModal/ResultModal";
import MapPage from "../../MapPage/MapPage";
import useGeolocation from 'react-hook-geolocation'

const QuestionCard = (props) => {
    const [isHistoryView, setIsHistoryView] = useState(false)
    const [isViewAnswer, setIsViewAnswer] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [geolocation, setGeolocation]=useState({})
    const  onGeolocationUpdate  =  g   =>  {
        setGeolocation(g)
    }
    const geo = useGeolocation({
        enableHighAccuracy : true ,
        maxAge :          15000 ,
        timeout :             700
    },onGeolocationUpdate )

    const closeModal = ()=>{
        setIsViewAnswer(false)
    }

    return !geolocation.latitude || geolocation.error ? (
        <>
            <div className={s.error_message_title}>Геолокация не включена.</div>
            <div className={s.error_message}>Пожалуйста, для прохождения квеста, включите геолокацию для браузера в настройках вашего телефона.</div>
        </>
    ) :(
<>
            {!isHistoryView?
                <>
                <div className={s.card_wrapper}>
                    <div className={s.content}>
                        <div className={s.title}>Вопрос №{props.currentTaskNumber+1}</div>
                        <div className={s.text}>{props.task.text}</div>
                    </div>

                    <div className={s.btn_wrapper}>
                        <div onClick={async ()=>{
                            const result = await props.checkAnswer(geolocation.latitude, geolocation.longitude)
                            if(!result.warning){
                                if(result.answer){
                                    props.setProgress(result.progress)
                                    setIsHistoryView(true)
                                    setAnswer(true)
                                    setIsViewAnswer(true)
                                }else{
                                    setAnswer(false)
                                    setIsViewAnswer(true)}
                                setTimeout(()=>{setIsViewAnswer(false)}, 1200)
                            }
                        }} className={'btn_blue'}>Проверить</div>
                        <MapPage/>
                        {/*{props.task.img? <img src={props.task.img} alt=""/>:<></>}*/}

                    </div>
                </div>
                </>:
                <>
                    <HistoryPage setIsHistoryView={setIsHistoryView} isFinish={props.isFinish} nextTask={props.nextTask} title={props.task.answer} text={props.task.description} img={props.task.img}/>
                </>}

    {isViewAnswer?<ResultModal close={closeModal} answer={answer}/>:<></>}
</>


    )
};

export default QuestionCard;