
import responseService from "../../Services/responseService";
import config from "../../config";
import UserClass from "./UserClass";


class UserState {

    async registration(user){
        // const newUser = new UserClass(username, password, 'user', isActive, 'new', description )
        const result = await responseService.responseApiServer(config.URL_REGISTRATION_USER, {user})
        return result
    }

    // async updatePassword(userid, password){
    //     this.messageUpdate = 'Сохраняю'
    //     const result = await  responseService.responseApiServer(config.URL_UPDATE_USER_PASSWORD, {userid, password})
    //     //console.log(result)
    //     runInAction(()=>{
    //     if(!result.warning){
    //         this.messageUpdate = 'Сохранено'
    //     }
    //     else{
    //         this.messageUpdate = 'Ошибка. '+ result.message
    //     }})
    // }
    //
    // async deleteUser(userid){
    //     this.messageDelete = 'Удаляю'
    //     const result = await  responseService.responseApiServer(config.URL_DELETE_USER, {userid})
    //     runInAction(()=>{
    //     if(!result.warning){
    //         this.messageUpdate = 'Удалено'
    //     }
    //     else{
    //         this.messageUpdate = 'Ошибка. '+ result.message
    //     }})
    // }

    async checkUsername(username){
       try{
           const result = await responseService.responseApiServer(config.URL_CHECK_USERNAME, {username})
           return result
       } catch (e) {
           return {warning:true, message:'Ошибка сервера'}
       }
    }
 async checkEmail(email){
       try{
           const result = await responseService.responseApiServer(config.URL_CHECK_EMAIL, {email})
           return result
       } catch (e) {
           return {warning:true, message:'Ошибка сервера'}
       }
    }
async updateUserPassword(email, key, password){
        try {
            const result = await responseService.responseApiServer(config.URL_UPDATE_USER_PASSWORD, {email, key, password})
            return result
        }catch (e) {
            return {warning:true, message:'Ошибка сервера'}
        }
}
async getUserId(){
        try{
            const result = await responseService.responseApiServer(config.URL_GET_USER_ID, {})
            return result
        }catch (e) {
            return {warning:true, message:'Ошибка сервера'}
        }
}
    // async addDescription(descriptionText){
    //     const result = await  responseService.responseApiServer(config.URL_ADD_DESCRIPTION, {descriptionText})
    //     return result
    // }


    
}



export default new UserState();

