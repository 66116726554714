import React, {useState,useEffect} from 'react';
import {QRCodeSVG} from 'qrcode.react';
import userState from '../../State/UserState/UserState'
import Loader from "../../Static/Loader/Loader";
import s from './TokenPage.module.css'
import {Link} from 'react-router-dom'
const TokenPage = () => {
    console.log(window.location.origin)
    const [userId, setUserId] = useState('')

    const init = async ()=>{
        const result = await userState.getUserId()
        if(result.warning)
            setTimeout(()=>{init()},1000)
        else
            setUserId(result.userId)
    }

    useEffect(()=>{
        init()
    },[])
    return (
        <div>
            {userId?
                <div>
                    <Link to={'/'}>
                        <div>Назад</div>
                    </Link>
                    <div className={s.qr_img}>
                        <QRCodeSVG size={{width:200,height:200 }} value={`${window.location.origin}/registration/${userId}`} />
                    </div>
                </div>
            : <Loader/>}
        </div>
    );
};

export default TokenPage;