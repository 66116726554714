import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import s from './RegistrationPage.module.css'
import userState from '../../State/UserState/UserState'
import Cookie from 'js-cookie'

const RegistrationPage = (props) => {
    const {token} = useParams();

    const [username, setUsername] = useState('')
    const [usernameIsFailed, setUsernameIsFailed] = useState(false)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [copyPassword, setCopyPassword] = useState('')
    const [city, setCity] = useState('')
    const [isNotEqualPassword, setIsNotEqualPassword] = useState(false)

    const saveUser = async ()=>{
        if(!username.trim()){
            props.warning('Поле "Имя пользователя" не может быть пустым')
            return
        }

        if(usernameIsFailed){
            props.warning('Пользователь с таким именем уже существует')
            return
        }

        if(!checkMail(email)){
            props.warning('Поле e-mail заполнено неверно')
            return
        }

        if (!password){
            props.warning('Поле пароль не может быть пустым')
            return
        }

        if(password!==copyPassword){
            props.warning('Введенные пароли не совпадают')
            return
        }


        const result = await userState.registration({username:username.trim(), role:'user', email:email.trim(), password, city, tokenWhereFrom:token, isActive:true})
        console.log(result)
        if(!result.warning && result.token) {
            Cookie.set('token', result.token, {expires: 20})
            window.location.href = window.location.origin
        }
        else
            props.warning('Ошибка сервера. Попробуйте зарегистрироваться позже')
    }

    const checkMail = (email)=>{
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return reg.test(email)
    }

    return (
        <div className={s.wrapper}>
            <Link to={'../'}>
                <div className={s.btn_back_quiz}>ᐸ На страницу авторизации</div>
            </Link>
            <input className={s.active_input} onChange={e=> {
                setUsername(e.target.value)
                e.target.className =  s.active_input
                setUsernameIsFailed(false)
            }}
                   onBlur={async (e)=>{
                     const currentUsername = e.target.value.trim()
                       if(currentUsername){
                           const result = await userState.checkUsername(currentUsername)
                           if(result.warning || !result.isCheck){
                               e.target.className =  s.no_active_input
                               setUsernameIsFailed(true)
                           }else if(result.isCheck){
                               e.target.className =  s.active_input
                               setUsernameIsFailed(false)
                           }
                       }else{
                           e.target.className =  s.no_active_input
                           setUsernameIsFailed(true)
                       }
                   }}
                   placeholder={'имя пользователя*'} type="text" value={username}/>
            {usernameIsFailed?<div className={s.warning_text_user}>{username.trim()?'Пользователь с таким именем уже существует':'Имя пользователя не может быть пустым'}</div>:<></>}
            <input className={s.active_input} onBlur={(e)=>{
                if(checkMail(e.target.value)) {
                    e.target.className = s.active_input
                }else e.target.className = s.no_active_input
            }} onChange={e=> {
                setEmail(e.target.value.trim())
            }} placeholder={'e-mail*'} type="text" value={email}/>
            <input onChange={e=>setPassword(e.target.value.trim())}
                   onBlur={(e)=>{
                       if(e.target.value.trim())
                            e.target.className = s.active_input
                       else
                           e.target.className = s.no_active_input
                   }}
                   className={s.active_input} placeholder={'пароль*'} type="password" value={password}/>
            <input onChange={e=> {
                setCopyPassword(e.target.value.trim())
                if(isNotEqualPassword && password === e.target.value.trim()){
                    e.target.className = s.active_input
                    setIsNotEqualPassword(false)
                }
            }}
                   onBlur={e=>{
                       if(password !== copyPassword){
                           setIsNotEqualPassword(true)
                           e.target.className = s.no_active_input
                       }else{
                           e.target.className = s.active_input
                           setIsNotEqualPassword(false)
                       }
                   }}
                   className={s.active_input} placeholder={'пароль повторно*'} type="password" value={copyPassword}/>
            {isNotEqualPassword?<div className={s.warning_text_user}>Пароли не совпадают</div>:<></>}
            <input onChange={e=>setCity(e.target.value)} className={s.active_input} placeholder={'город проживания'} type="text" value={city}/>
            <div onClick={async ()=>{saveUser()}} className={s.btn_save}>Сохранить</div>
        </div>
    );
};

export default RegistrationPage;