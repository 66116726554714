import responseService from "../../Services/responseService";
import config from "../../config";

class QuestionState{
    async pullAnswer(quiz_id, answer){
        const result = await responseService.responseApiServer(config.URL_CHANGE_GEO_ANSWER, {answer,quiz_id})
        return result
    }
}

export default new QuestionState()